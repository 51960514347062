import React from "react"
import Button from "react-bootstrap/Button"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import Icon from "../components/Icon"
import SEO from "../components/SEO"
import GoogleMaps from "../components/GoogleMaps"

import "./index.scss"

const Index = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Quality Used Cars &amp; Commercials"
      description="Solomon Motor Group is the Number 1 Used Car Dealership Network in Nowra, Shoalhaven in two great locations on the Princes Highway"
    />
    <Heading title="Welcome to Solomon Motor Group" />
    <SubHeading subHeading="Everyday is a Sale Day at Solomons!" />
    <div className="row justify-content-center">
      <div className="col-lg-8 col-md-12 text-center">
        <p>
          Solomon Motors was established in 1971 providing quality used cars to
          the Shoalhaven area. To this day, Solomon Motor Group prides itself on
          providing fantastic service and quality used vehicles to its new and
          existing customers.
        </p>
        <p>
          With 2 dealerships located on the Princes Highway, we have approx. 100
          used cars in stock at all times ranging from small and economical to
          commercial vehicles. Since opening, we have sold over 25,000 Used
          Vehicles.
        </p>
        <p>
          The team at Solomon Motor Group are focused on ensuring your next
          vehicle purchase is a smooth and enjoyable experience.
        </p>
      </div>
    </div>
    <Heading title="2 Great Locations on the Princes Highway" />
    <div className="row">
      <div className="col-12 col-md-6">
        <SubHeading subHeading="Solomon Motors City" />
        <div className="row google-maps-container">
          <div className="col-12">
            <GoogleMaps
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              mapCenter={{ lat: -34.908845, lng: 150.60355 }}
              isMarkerShown
              markerCenter={{ lat: -34.908845, lng: 150.60355 }}
              zoom={17}
            />
          </div>
        </div>

        <div className="row buttons-container">
          <div className="col-12">
            <ul className="nav justify-content-center">
              <li className="nav-item mx-2 mb-3">
                <a href="tel:0244230222">
                  <Button variant="primary" bsPrefix="smg">
                    Phone <Icon prefix="fas" name="phone" />
                  </Button>
                </a>
              </li>
              <li className="nav-item mx-2 mb-3">
                <a
                  href="https://www.google.com/maps/place/Solomon+Motor+Group/@-34.8910658,150.5873489,14z/data=!4m8!1m2!2m1!1ssolomon+motors+city!3m4!1s0x6ad661b6ef2d6dbf:0x33917e13647bf0c8!8m2!3d-34.9088274!4d150.6035471"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="primary" bsPrefix="smg">
                    Get Directions <Icon prefix="fas" name="map-marker-alt" />
                  </Button>
                </a>
              </li>
              <li className="nav-item mx-2 mb-3">
                <Link to="/used-vehicles/solomon-motors-city">
                  <Button variant="primary" bsPrefix="smg">
                    View Stock <Icon prefix="fas" name="arrow-circle-right" />
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 text-center">
            <p className="details-heading">Contact Details</p>
            <p className="details-subheading">Phone</p>
            <p>(02) 4423 0222</p>
            <p className="details-subheading">Address</p>
            <p>Cnr Princes Highway &amp; Quinns Lane, South Nowra, NSW</p>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <p className="details-heading">Trading Hours</p>
            <p className="details-subheading">Monday - Friday</p>
            <p>8:30am - 5:00pm</p>
            <p className="details-subheading">Saturday</p>
            <p>8:30am - 2:30pm</p>
            <p className="details-subheading">Sunday</p>
            <p>Closed</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <SubHeading subHeading="Solomons Mid City Autos" />
        <div className="row google-maps-container">
          <div className="col-12">
            <GoogleMaps
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              mapCenter={{ lat: -34.873356, lng: 150.606204 }}
              isMarkerShown
              markerCenter={{ lat: -34.873356, lng: 150.606204 }}
              zoom={17}
            />
          </div>
        </div>

        <div className="row buttons-container">
          <div className="col-12">
            <ul className="nav justify-content-center">
              <li className="nav-item mx-2 mb-3">
                <a href="tel:0244213666">
                  <Button variant="primary" bsPrefix="smg">
                    Phone <Icon prefix="fas" name="phone" />
                  </Button>
                </a>
              </li>
              <li className="nav-item mx-2 mb-3">
                <a
                  href="https://www.google.com/maps/place/Solomons+Mid+City+Autos/@-34.8733065,150.603982,17z/data=!3m1!4b1!4m5!3m4!1s0x6b148021d304f71b:0x3928d42a703bd9e6!8m2!3d-34.8733065!4d150.6061707"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="primary" bsPrefix="smg">
                    Get Directions <Icon prefix="fas" name="map-marker-alt" />
                  </Button>
                </a>
              </li>
              <li className="nav-item mx-2 mb-3">
                <Link to="/used-vehicles/solomons-mid-city-autos">
                  <Button variant="primary" bsPrefix="smg">
                    View Stock <Icon prefix="fas" name="arrow-circle-right" />
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 text-center">
            <p className="details-heading">Contact Details</p>
            <p className="details-subheading">Phone</p>
            <p>(02) 4421 3666</p>
            <p className="details-subheading">Address</p>
            <p>Cnr Princes Highway &amp; North Street, Nowra, NSW</p>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <p className="details-heading">Trading Hours</p>
            <p className="details-subheading">Monday - Friday</p>
            <p>8:30am - 5:00pm</p>
            <p className="details-subheading">Saturday</p>
            <p>8:30am - 2:30pm</p>
            <p className="details-subheading">Sunday</p>
            <p>Closed</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Index
